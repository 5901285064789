import {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Info from 'components/shared/info/Info';
import DeleteForm from 'components/shared/deleteForm/DeleteForm';
import ScreenMessage from 'components/shared/screenMessage/ScreenMessage';

import type { IReportResult, ServerResponse } from 'store/interfaces/IData';
import { useStore } from 'store/store';
import {
  AppRoutes, QueryParams, Statuses, FolderNames,
} from 'enums';
import {
  STYLES_BUTTON, REREQUEST_TIME,
  STORAGE_LANG, HIDE_SNACKBAR_TIME,
} from '../../../../constants';

import Actions from './actions/Actions';
import ImageArea from './imageArea/ImageArea';
import ErrorsList from './errorsList/ErrorsList';
import ProofToggle from './proofToggle/ProofToggle';
import SnackBar from '../../../shared/snackBar/SnackBar';
import { sendCloseMessage } from '../../../../utils/messageService';

import './Feedback.scss';

const Feedback: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {
    isLoaded, jobId, orderData, checks, taskStatus,
    getResult, getReportFileByName, getTaskStatus,
    setLoaded, setImageData, setPreflightedFile, setTaskStatus,
    destroySession, reset, acceptJob,
  } = useStore();
  const [approve, setApprove] = useState(false);
  const [accept, setAccept] = useState(false);
  const [proof, setProof] = useState(false);
  const [error, setError] = useState(null);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [result, setResult] = useState<ServerResponse>({
    preflightedFile: '',
    previewImage: '',
    report: '',
    reportResult: {} as IReportResult,
    status: Statuses.starting,
    error: '',
  });

  const handleDelete = useCallback(async () => {
    await destroySession();
    navigate(`${AppRoutes.upload}?${QueryParams.JOB_ID}=${jobId}&${QueryParams.LANG}=${localStorage.getItem(STORAGE_LANG)}&${QueryParams.ORDER_PRODUCT}=${orderData.product}&${QueryParams.ORDER_LINE}=${orderData.line}&${QueryParams.IV_WIDTH}=${orderData.ivWidth}&${QueryParams.IV_HEIGHT}=${orderData.ivHeight}`);
    reset();
  }, [orderData, jobId]);

  const handleAccept = useCallback(() => {
    setTaskStatus(Statuses.pending);
    acceptJob({ generateProofPage: String(proof) }).catch((e) => setError(e.message));
    setAccept(true);
  }, [proof]);

  useEffect(() => {
    if (!isLoaded) {
      navigate(AppRoutes.base);
      reset();
    }
    setLoaded(false);
    setProof(searchParams.get(QueryParams.GENERATE_PROOF_PAGE)?.toLowerCase() !== 'false');
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (taskStatus.status === Statuses.processing || taskStatus.status === Statuses.pending) {
      intervalId = setInterval(() => {
        getTaskStatus().catch((error) => {
          setTaskStatus(Statuses.starting);
          console.error(error);
        });
      }, REREQUEST_TIME);
    } else if (taskStatus.status === Statuses.completed || taskStatus.status === Statuses.failed) {
      if (!accept) {
        clearInterval(intervalId);
        getResult()
          .then((result: ServerResponse) => {
            setResult({
              ...result,
              status: result.status,
              error: result.error,
            });
          })
          .catch((error) => {
            setTaskStatus(Statuses.starting);
            console.error(error);
          });
      }
    }
    return () => clearInterval(intervalId);
  }, [taskStatus, accept]);

  const isLoading = useMemo(() => {
    return [
      Statuses.uploading,
      Statuses.processing,
      Statuses.pending,
    ].includes(taskStatus.status);
  }, [taskStatus]);

  useEffect(() => {
    const fetchData = async () => {
      if (taskStatus.status === Statuses.completed) {
        await getReportFileByName(FolderNames.reports, result.previewImage ?? '').then((blob) => {
          const img = new Image();
          const imageUrl = URL.createObjectURL(blob as Blob);
          img.src = imageUrl;
          img.onload = () => {
            setImageData({
              url: imageUrl,
              height: img.naturalHeight,
              width: img.naturalWidth,
            });
          };
        });
        if (result.preflightedFile !== undefined) {
          await getReportFileByName(FolderNames.reports, result.preflightedFile).then((blob) => {
            const fileUrl = URL.createObjectURL(blob as Blob);
            setPreflightedFile(fileUrl);
          });
        }
        setLoaded(true);
      } else if (result.status === Statuses.failed) {
        console.error('Error downloading preflightedFile');
      }
    };
    fetchData().catch(console.error);
  }, [result]);

  useEffect(() => {
    if (error) {
      setIsSnackBarOpen(true);
      setTimeout(() => {
        setError(null);
        setIsSnackBarOpen(false);
      }, HIDE_SNACKBAR_TIME);
    }
  }, [error]);

  const handleSnackbarClose = () => {
    setIsSnackBarOpen(false);
  };

  const message = useMemo(() => {
    return taskStatus.status === Statuses.completed
      ? t('label_accept_message')
      : t('label_unexpected_error');
  }, [taskStatus]);

  const showMessage = useMemo(() => {
    return accept && !isLoading;
  }, [accept, isLoading]);

  const loadingLabel = useMemo(() => {
    return accept && isLoading ? t('label_tab_can_be_closed') : '';
  }, [accept, isLoading]);

  useEffect(() => {
    let timeoutId: any;
    if (taskStatus.status === Statuses.completed && showMessage) {
      timeoutId = setTimeout(() => {
        sendCloseMessage();
      }, 3000);
    }
    return () => clearTimeout(timeoutId);
  }, [showMessage, taskStatus]);

  return (
    <>
      {
        !showMessage ? (
          <section className="main-container">
            <ImageArea isLoading={isLoading} loadingLabel={loadingLabel} />
            <div className="aside feedback">
              <div className="feedback-wrapper">
                <Info>
                  <DeleteForm handleClick={handleDelete} disabled={accept && isLoading} />
                </Info>
                <ErrorsList
                  errors={checks}
                  approve={approve}
                  setApprove={setApprove}
                />
                <Actions />
                <ProofToggle
                  proof={proof}
                  setProof={setProof}
                  disabled={accept && isLoading}
                />
                <Button
                  className="feedback__btn"
                  variant="contained"
                  sx={STYLES_BUTTON}
                  disabled={!approve || isLoading}
                  onClick={handleAccept}
                >
                  {t('btn_complete')}
                </Button>
              </div>
            </div>
          </section>
        ) : (
          <ScreenMessage message={message} />
        )
      }
      <SnackBar
        isOpen={isSnackBarOpen}
        successes={[]}
        errors={error ? [error] : []}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};

export default Feedback;
